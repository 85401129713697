import checkIsValidImage from '../utils/is-valid-image';
import { client, shouldRetryAll } from '../../utils/axios';
import getFileExtension from '../utils/get-file-extension';
import replaceFileExtension from '../utils/replace-file-extension';

export default {
	methods: {
		processUploads() {
			this.uploads.forEach((upload) => {
				// Sometimes due to triggers this can be re-ran and we don't want to re-do the same work
				if(upload.converted || upload.converting || upload.error || upload.rejectReason || upload.checkingFormat || upload.checkedFormat) {
					return;
				}

				if(this.imageRequirements.validTypes.indexOf(upload.file.type.toLowerCase()) === -1) {
					let extension = getFileExtension(upload.file.name);
					// Images coming from GDrive have the correct mime type even if they have a jpg extension
					if(upload.file.type === 'image/webp') {
						extension = 'webp';
					} else if(upload.file.type === 'image/heic' || upload.file.type === 'image/heif') {
						extension = 'heic';
					}

					if(this.photoConverters[extension]) {
						// Don't try to run more than once
						if(upload.converting) {
							return;
						}

						let converter = this.photoConverters[extension].converter;
						let fileBlob = upload.file;
						if(fileBlob.downloadUrl && fileBlob.source === 'google-drive') {
							// Had a customer who somehow had drive.files throw an error about being undefined here - should make sure we don't try to upload photo we can't download
							try {
								upload.converting = window.gapi.client.drive.files.get({
									fileId: fileBlob.id,
									alt: 'media'
								}).then(response => {
									let binary = response.body;
									let array = new Uint8Array(binary.length);
									for (var i = 0; i < binary.length; i++){
										array[i] = binary.charCodeAt(i);
									}
									let blob = new Blob([array], {type: 'application/octet-stream'});

									return converter(blob);
								}).catch(error => {
									upload.converting = false;
									upload.rejectReason = this.i18n('uploader.errors.download');
									console.error('error: ', error);
								});
							} catch(error) {
								upload.converting = false;
								upload.rejectReason = this.i18n('uploader.errors.convertToJpg');
								console.error('error: ', error);
							}
						} else if(fileBlob.downloadUrl) {
							// Download url does not pass CORS so we can't download directly in the client
							// window.gapi.client.drive.photoslibrary does not work with alt: 'media'
							if(fileBlob.source === 'google-photos') {
								fileBlob.downloadUrl = `${process.env.VUE_APP_DEFAULT_HOST}api/v1/stream-url?url=${fileBlob.downloadUrl}`;
							}

							upload.converting = client.get(fileBlob.downloadUrl, {
								responseType: 'blob',
								shouldRetry: shouldRetryAll
							}).then(response => {
								let blob = response.data;
								return converter(blob);
							}).catch(error => {
								upload.converting = false;
								upload.rejectReason = this.i18n('uploader.errors.download');
								console.error('error: ', error);
							});
						} else {
							upload.converting = converter(fileBlob);
						}

						if(upload.converting && upload.converting.then) {
							upload.converting.then((newBlob) => {
								let newExtension = 'jpg';
								let newType = 'image/jpeg';
								if(newBlob.type === 'image/png') {
									newExtension = 'png';
									newType = newBlob.type;
								}

								upload.file = new File([newBlob], replaceFileExtension(upload.file.name, newExtension), {
									type: newType
								});
								upload.converting = false;
								upload.converted = true;

								this.checkIsValidImage(upload);
							}).catch((error) => {
								upload.converting = false;
								upload.rejectReason = this.i18n('uploader.errors.convertToJpg');
								console.error('error: ', error);
							});
						}
					} else {
						upload.rejectReason = this.i18n('uploader.errors.supportedType');
					}
				} else {
					if(this.checkIsValidImage(upload) && (!this.isValidImage || this.isValidImage(upload))) {
						upload.checkingFormat = this.checkImageFormatMislabeled(upload).finally(() => {
							// Clear checkingFormat when done so we can show image loading until this is done
							upload.checkingFormat = false;
							upload.checkedFormat = true;
						});
					}
				}
			});
		},
		async checkImageFormatMislabeled(upload) {
			if(upload.converting || !upload.file) {
				return;
			}

			let photoConverters = this.photoConverters;
			const BYTES_TO_LOAD = 12;
			let arrayBuffer;
			if(upload.file.downloadUrl && upload.file.source === 'google-drive') {
				const response = await client.get(`https://www.googleapis.com/drive/v2/files/${upload.file.id}?alt=media`, {
					responseType: 'arraybuffer',
					headers: {
						Authorization: `Bearer ${upload.file.authToken}`,
						Range: `bytes=0-${BYTES_TO_LOAD}`
					}
				});
				arrayBuffer = response.data;

				// NOTE: Probably wouldn't be hard to add support for implicit converters but the webp/heic files I tried all were detected by Google Drive and had the correct type even when the extension was wrong
				photoConverters = null;
			} else if(upload.file.arrayBuffer) {
				arrayBuffer = await upload.file.arrayBuffer();
			} else {
				return;
			}

			if(upload.converting) {
				return;
			}
			if(arrayBuffer.byteLength < BYTES_TO_LOAD) {
				upload.rejectReason = window.i18n.t('uploader.errors.empty');
				return;
			}
			let dataView = new DataView(arrayBuffer, 0, BYTES_TO_LOAD);
			/*for(let i = 0; i < BYTES_TO_LOAD; i++) {
				console.log(dataView.getUint8(i, true).toString(16));
			}*/

			const rejectSignatures = [
				{
					type: 'TIFF',
					signature: {
						0: 73,
						1: 73,
						2: 42,
						3: 0
					}
				},
				{
					type: 'TIFF',
					signature: {
						0: 77,
						1: 77,
						2: 0,
						3: 42
					}
				},
				{
					type: 'BPlist',
					signature: {
						0: 0x62,
						1: 0x70,
						2: 0x6c,
						3: 0x69,
						4: 0x73,
						5: 0x74
					}
				}
			];

			for(let extension in photoConverters) {
				let photoConverter = photoConverters[extension];
				if(!photoConverter.signatures) {
					continue;
				}

				// See if all signature fields match
				for(let i = 0; i < photoConverter.signatures.length; i++) {
					let signature = photoConverter.signatures[i];
					let indexes = Object.keys(signature);
					let matchedIndexes = indexes.filter((index) => {
						return dataView.getUint8(index, true) === signature[index];
					});
					if(indexes.length === matchedIndexes.length) {
						upload.converting = photoConverter.converter(upload.file);

						try {
							let newBlob = await upload.converting;
							upload.file = new File([newBlob], upload.file.name, {
								type: 'image/jpeg'
							});
							upload.converting = false;
							upload.converted = true;
							
							this.checkIsValidImage(upload);
						} catch(error) {
							upload.converting = false;
							// NOTE: We do NOT want to reject a file which may already be uploading by the time this check is complete
							// upload.rejectReason = this.i18n('uploader.errors.convertToJpg');
							console.error('error: ', error);
						}
						return;
					}
				}
			}

			for(let i = 0; i < rejectSignatures.length; i++) {
				let signature = rejectSignatures[i];
				let indexes = Object.keys(signature.signature);
				let matchedIndexes = indexes.filter((index) => {
					return dataView.getUint8(index, true) === signature.signature[index];
				});
				if(indexes.length === matchedIndexes.length) {
					let rejectReason = this.i18n('uploader.errors.fileDetected', {
						type: signature.type
					});
					// If we rejected the file while uploading we need to make sure that we report this as an error so the user can see it
					if(upload.uploading) {
						upload.error = rejectReason;
					} else {
						upload.rejectReason = rejectReason;
					}
					upload.converting = false;
					console.error(upload.rejectReason);
					return;
				}
			}

			// TODO: This should probably still run post-conversion as well
			// TODO: Should we cache this img object so we can re-use in the actual upload?
			if(this.uploadPhotoRequirements && upload.file.arrayBuffer) {
				let img = await loadImagePromise(upload);
				if((this.uploadPhotoRequirements.minWidth && img.width < this.uploadPhotoRequirements.minWidth) || (this.uploadPhotoRequirements.minHeight && img.height < this.uploadPhotoRequirements.minHeight)) {
					upload.rejectReason = this.i18n('uploader.errors.minDimensions', {
						width: img.width,
						height: img.height,
						minWidth: this.uploadPhotoRequirements.minWidth,
						minHeight: this.uploadPhotoRequirements.minHeight
					});
					upload.converting = false;
					console.error(upload.rejectReason);
				}
			}
		},

		checkIsValidImage
	}
};

function loadImagePromise(upload) {
	return new Promise((resolve) => {
		let img = new Image();
		img.onload = () => {
			resolve(img);
		};
		img.onerror = (error) => {
			console.error('failed to load image: ', error);
			resolve(img);

			// Explicitly make sure this is unloaded from memory
			URL.revokeObjectURL(img.src);
		};
		img.src = URL.createObjectURL(upload.file);
	});
}