$.FlowLayoutAlignmentLines = function(div) {
	$.extend(div, {
		snapToAlignmentLines: function(uiPosition, onMove, options) {
			if(!options) {
				options = {};
			}

			var possibleAlignmentLines = this.getPossibleAlignmentLines(options);
			var closestHorizontal = possibleAlignmentLines.closestHorizontal;
			var closestVertical = possibleAlignmentLines.closestVertical;

			// Display closest
			if(closestVertical) {
				this.snapToAlignmentLine(closestVertical, uiPosition);

				if(closestHorizontal) {
					if(closestHorizontal.side == 'Min' && closestHorizontal.uiDiff > 0) {
						$(closestVertical.line).css({
							top: $(closestVertical.line).getFloatStyle('top') + closestHorizontal.uiDiff,
							height: $(closestVertical.line).getFloatStyle('height') - closestHorizontal.uiDiff
						});
					} else if(closestHorizontal.side == 'Max' && closestHorizontal.uiDiff < 0) {
						$(closestVertical.line).css({
							height: $(closestVertical.line).getFloatStyle('height') + closestHorizontal.uiDiff
						});
					}
				}
			}
			if(closestHorizontal) {
				this.snapToAlignmentLine(closestHorizontal, uiPosition);

				if(closestVertical) {
					if(closestVertical.side == 'Min' && closestVertical.uiDiff > 0) {
						$(closestHorizontal.line).css({
							left: $(closestHorizontal.line).getFloatStyle('left') + closestVertical.uiDiff,
							width: $(closestHorizontal.line).getFloatStyle('width') - closestVertical.uiDiff
						});
					} else if(closestVertical.side == 'Max' && closestVertical.uiDiff < 0) {
						$(closestHorizontal.line).css({
							width: $(closestHorizontal.line).getFloatStyle('width') + closestVertical.uiDiff
						});
					}
				}
			}

			if(!this.alignmentLines.length && onMove) {
				onMove();
			}

			if((closestVertical || closestHorizontal) && options.onSnapToAlignmentLine) {
				options.onSnapToAlignmentLine.call(this, closestHorizontal, closestVertical);
			}
		},
		getPossibleAlignmentLines: function(options) {
			var me = this;
			// Figure out if we should be showing alignment tools for anything
			var canvasRect = this.wrapper.canvasMargins.getBoundingClientRect();
			var canvasMargins = {
				left: $(this.wrapper.canvasMargins).getFloatStyle('margin-left'),
				top: $(this.wrapper.canvasMargins).getFloatStyle('margin-top')
			};
			
			var contentRect = this.getOuterContentRect();
			var contentPosition = {
				left: (contentRect.left - canvasRect.left),
				top: (contentRect.top - canvasRect.top)
			};

			var containInParent = this.containInParent;
			// Don't want to be able to drag an image and a text node across boundary if text node doesn't flow
			if(this.getSecondaryFocusedElements && !containInParent) {
				this.getSecondaryFocusedElements().forEach(function(secondaryElement) {
					if(secondaryElement.containInParent) {
						containInParent = true;
					}
				});
			}

			var alignmentLines = [];
			var alignTargets = $();
			if(this.alignmentLinesEnabled) {
				alignTargets = $(this.wrapper).find('.flowAlignTarget').not(this).not(this.getBlockedElementsFromAlignment());
			} else if(containInParent) {
				alignTargets = $(this.wrapper).find('.flowAlignTarget.flowPageMargins');
			}

			if(this.overflowToLinkedLayouts && this.linkedOverflowInstance && !this.duplicateLinkedLayoutsInBleed) {
				alignTargets = alignTargets.add(this.wrapper);
			}

			alignTargets.each(function () {
				var targetRect = this.getBoundingClientRect();

				var axises = [
					{
						vertical: true,
						min: 'top',
						max: 'bottom',
						pointMin: 'left',
						pointMax: 'right',
						size: 'width'
					},
					{
						vertical: false,
						min: 'left',
						max: 'right',
						pointMin: 'top',
						pointMax: 'bottom',
						size: 'height'
					}
				];
				// MinMax => Min content Max target bounds
				let allAlignments = ['Min', 'Center', 'Max', 'MinMax', 'MaxMin'];
				if(options.noCenterAlignment) {
					allAlignments.removeItem('Center');
				}
				if($(this).hasClass('flowLayoutGridLine')) {
					allAlignments = ['Min', 'Max'];
				} else if($(this).hasClass('flowPage')) {
					// Align center of content to min/max of bounds
					allAlignments = ['CenterMin', 'CenterMax'];
					axis = axises.filter(axis => !!axis.vertical);
				}
				
				for (let i = 0; i < axises.length; i++) {
					var axis = axises[i];
					var passOptions = $.extend(true, {}, options);
					let alignments = allAlignments.slice();
					if(axis.vertical && options.ignoreVerticalSides) {
						alignments = allAlignments.filter(alignment => !options.ignoreVerticalSides.includes(alignment));
					} else if(!axis.vertical && options.ignoreHorizontalSides) {
						alignments = allAlignments.filter(alignment => !options.ignoreHorizontalSides.includes(alignment));
					}
					// For spine text it is funky to move in narrow spine if they keep aligning against inside margins
					// MaxMin for example means only don't align the right side of the text box to the left page's inside border.  The left side of the box will still align.
					if($(this).hasClass('flowPageMargins') && axis.vertical && axis.pointMax === 'right') {
						if(me.containInLeftParent === false) {
							alignments = alignments.filter(alignment => !['MaxMin', 'CenterMin'].includes(alignment));
						}

						if(me.containInRightParent === false) {
							alignments = alignments.filter(alignment => !['MinMax', 'CenterMax'].includes(alignment));
						}
					}

					var canvas = {
						min: canvasRect[axis.pointMin] - canvasMargins[axis.pointMin],
						start: canvasRect[axis.min] - canvasMargins[axis.min],
						minMargin: canvasMargins[axis.pointMin]
					};

					for (var j = 0; j < alignments.length; j++) {
						var alignment = alignments[j];
						var contentMatch = {
							min: contentRect[axis.min],
							max: contentRect[axis.max]
						};
						var targetMatch = {
							min: targetRect[axis.min],
							max: targetRect[axis.max]
						};

						if (alignment == 'Min' || alignment == 'MinMax') {
							contentMatch.point = contentRect[axis.pointMin];
						} else if (alignment == 'Center' || alignment === 'CenterMin' || alignment === 'CenterMax') {
							contentMatch.point = (contentRect[axis.pointMin] + contentRect[axis.pointMax]) / 2;
						} else {
							contentMatch.point = contentRect[axis.pointMax];
						}

						if (alignment == 'Min' || alignment == 'MaxMin' || alignment === 'CenterMin') {
							targetMatch.point = targetRect[axis.pointMin];
						} else if (alignment == 'Center') {
							targetMatch.point = (targetRect[axis.pointMin] + targetRect[axis.pointMax]) / 2;
						} else {
							targetMatch.point = targetRect[axis.pointMax];
						}

						// Non-min (ie: top) gets a size passed as well
						if (alignment != 'Min' && alignment != 'MinMax') {
							targetMatch.pointMin = targetRect[axis.pointMin];

							if (alignment == 'Center' || alignment == 'CenterMin' || alignment == 'CenterMax') {
								contentMatch.size = contentRect[axis.size] / 2;
							} else {
								contentMatch.size = contentRect[axis.size];
							}
						}

						if($(this).hasClass('flowPage')) {
							if(alignment === 'CenterMax') {
								targetMatch.point = targetMatch.point + 1 - me.wrapper.canvasBorderWidth;
							}
						}

						var containWithin = false;
						if(containInParent && $(this).hasClass('flowPageMargins')) {
							containWithin = true;

							if(axis.vertical && axis.pointMax === 'right' && !options.forceContainInParent) {
								if(me.containInLeftParent === false) {
									containWithin = false;
									passOptions.containWithinMax = true;
								}

								if(me.containInRightParent === false) {
									containWithin = false;
									passOptions.containWithinMin = true;
								}
							}
						}

						var alignmentLine = me.checkAlignmentTarget(this, contentPosition, axis.vertical, alignment, contentMatch, targetMatch, canvas, containWithin, passOptions);
						if(alignmentLine) {
							alignmentLines.push(alignmentLine);
						}
					}
				}
			});

			// Figure out which ones are closest
			var closestVertical = null, closestHorizontal = null;
			for (let i = 0; i < alignmentLines.length; i++) {
				let definition = alignmentLines[i];

				if(definition.vertical) {
					if(
						// First match we came across
						!closestVertical ||
						// We are matching against the min of a boundary when we are supposed to stay within bounds
						(containInParent && $(definition.target).hasClass('flowPageMargins')) ||
						// We are closer than the next closest with either no existing preferred side match OR both sides match preferred side
						(definition.distanceToElement < closestVertical.distanceToElement && (!options.preferVerticalSide || definition.side === options.preferVerticalSide || closestVertical.preferredSide !== options.preferVerticalSide)) ||
						// OR we are the first side that matches the preffered side
						(options.preferVerticalSide && definition.preferredSide === options.preferVerticalSide && closestVertical.preferredSide !== options.preferVerticalSide)
					) {
						// Do not override existing choise against margins
						if(containInParent && closestVertical && $(closestVertical.target).hasClass('flowPageMargins')) {
							continue;
						}

						closestVertical = definition;
					}
				} else {
					if(
						// First match we came across
						!closestHorizontal ||
						// We are matching against the min of a boundary when we are supposed to stay within bounds
						(containInParent && $(definition.target).hasClass('flowPageMargins')) ||
						// We are closer than the next closest with either no existing preferred side match OR both sides match preferred side
						(definition.distanceToElement < closestHorizontal.distanceToElement && (!options.preferHorizontalSide || definition.side === options.preferHorizontalSide || closestHorizontal.preferredSide !== options.preferHorizontalSide)) ||
						// OR we are the first side that matches the preffered side
						(options.preferHorizontalSide && definition.preferredSide === options.preferHorizontalSide && closestHorizontal.preferredSide !== options.preferHorizontalSide)
					) {
						// Do not override existing choise against margins
						if(containInParent && closestHorizontal && $(closestHorizontal.target).hasClass('flowPageMargins')) {
							continue;
						}

						closestHorizontal = definition;
					}
				}
			}

			// Remove all the ones which weren't closest
			for (let i = 0; i < alignmentLines.length; i++) {
				let definition = alignmentLines[i];
				if (definition != closestHorizontal && definition != closestVertical) {
					this.removeAlignmentLine(definition);
				}
			}

			return {
				alignmentLines: alignmentLines,
				closestHorizontal: closestHorizontal,
				closestVertical: closestVertical
			};
		},
		getBlockedElementsFromAlignment: function() {
			var secondaryFocusedElements = this.getSecondaryFocusedElements();
			
			var otherBlocks = [];
			secondaryFocusedElements.forEach(function(element) {
				if(!element.getOtherBlockedElementsFromAlignment) {
					return;
				}

				$.merge(otherBlocks, element.getOtherBlockedElementsFromAlignment());
			});
			
			return $.merge(otherBlocks, secondaryFocusedElements);
		},
		getOuterContentRect: function() {
			var contentRect = this.getBoundingClientRect();
			contentRect = {
				left: contentRect.left,
				right: contentRect.right,
				top: contentRect.top,
				bottom: contentRect.bottom
			};
			// We do not want to snap against edges when resizing all frames in a locked collage layout
			var secondaryElements = this.getSecondaryFocusedElementsForResize ? this.getSecondaryFocusedElementsForResize() : this.getSecondaryFocusedElements();
			// Make sure moving an image on left page and text on right page does not try to align everything on together
			secondaryElements = secondaryElements.filter(secondaryElement => secondaryElement.wrapper === this.wrapper);
			let groupBorder = null;
			if(this.instance && this.instance.groupBorder) {
				groupBorder = this.instance.groupBorder;
			}
			secondaryElements.forEach(function(secondaryElement) {
				var secondaryRect = secondaryElement.getBoundingClientRect();

				['left', 'top'].forEach(function(minProp) {
					if(secondaryRect[minProp] < contentRect[minProp]) {
						contentRect[minProp] = secondaryRect[minProp];
					}
				});

				['right', 'bottom'].forEach(function(maxProp) {
					if(secondaryRect[maxProp] > contentRect[maxProp]) {
						contentRect[maxProp] = secondaryRect[maxProp];
					}
				});

				if(!groupBorder && secondaryElement.instance && secondaryElement.instance.groupBorder) {
					groupBorder = secondaryElement.instance.groupBorder;
				}
			});

			contentRect.width = contentRect.right - contentRect.left;
			contentRect.height = contentRect.bottom - contentRect.top;
			if(groupBorder) {
				let thickness = groupBorder.thickness / 60 * this.ratio;
				let padding = groupBorder.padding / 60 * this.ratio;

				contentRect.left = contentRect.left - thickness - padding;
				contentRect.top = contentRect.top - thickness - padding;
				contentRect.width = contentRect.width + (thickness + padding) * 2;
				contentRect.height = contentRect.height + (thickness + padding) * 2;
			}

			return contentRect;
		},
		snapToAlignmentLine: function (definition, uiPosition) {
			var alignmentLine = definition.line;
			var target = alignmentLine.data('alignmentTarget');

			this.alignmentLines = this.alignmentLines.add(alignmentLine);
			$(target).data(definition.dataPrefix + 'Alignment', alignmentLine);
			$(this.wrapper.container).append(alignmentLine);

			uiPosition[definition.uiIdentifier] += definition.uiDiff;

			var currentPosition = $(this).getFloatStyle(definition.uiIdentifier);
			$(this).css(definition.uiIdentifier, currentPosition + definition.uiDiff);

			this.getSecondaryFocusedElements().forEach(function(secondaryElement) {
				var secondaryPosition = $(secondaryElement).getFloatStyle(definition.uiIdentifier);
				$(secondaryElement).css(definition.uiIdentifier, secondaryPosition + definition.uiDiff);
			});
		},
		removeAlignmentLines: function() {
			if (this.alignmentLines.length) {
				this.alignmentLines.each(function () {
					var target = $(this).data('alignmentTarget');

					var alignments = ['Min', 'Center', 'Max'];
					for (let i = 0; i < alignments.length; i++) {
						var alignment = alignments[i];
						$(target).data('vertical' + alignment + 'Alignment', null);
						$(target).data('horizontal' + alignment + 'Alignment', null);
					}
				});

				this.alignmentLines.remove();
				this.alignmentLines = $();
			}
		},
		removeAlignmentLine: function (definition) {
			var alignmentLine = definition.line;

			if (alignmentLine && alignmentLine.isAttached()) {
				var target = alignmentLine.data('alignmentTarget');

				alignmentLine.remove();
				this.alignmentLines = this.alignmentLines.not(alignmentLine);
				$(target).data(definition.dataPrefix + 'Alignment', null);
			}
		},

		checkAlignmentTarget: function (target, uiPosition, vertical, side, contentMatch, targetMatch, canvas, containWithin, options) {
			var prefix = vertical ? 'vertical' : 'horizontal';
			var dataPrefix = prefix + side;

			var alignmentLine = $(target).data(dataPrefix + 'Alignment');
			var matchDiff = targetMatch.point - contentMatch.point;
			if (Math.abs(matchDiff) < $.FlowLayoutAlignmentLines.SNAP_DISTANCE ||
				(containWithin && ((side == 'Max' && matchDiff < 0) || (side == 'Min' && matchDiff > 0))) ||
				(options.containWithinMax && side == 'Max' && matchDiff < 0) ||
				(options.containWithinMin && side == 'Min' && matchDiff > 0)) {
				// Create if not existing
				if (!alignmentLine) {
					alignmentLine = $('<div class="pageCenterLines ' + prefix + 'CenterLine">');
					alignmentLine.data('alignmentTarget', target);
				}

				// Update line length
				var lengthIdentifier, minIdentifier, positionIdentifier, sizeIdentifier;
				if (vertical) {
					lengthIdentifier = 'height';
					minIdentifier = 'top';
					positionIdentifier = 'left';
					sizeIdentifier = 'width';
				} else {
					lengthIdentifier = 'width';
					minIdentifier = 'left';
					positionIdentifier = 'top';
					sizeIdentifier = 'height';
				}
				var length, startingPosition, distanceToElement;
				if ($.isInit(contentMatch.min)) {
					if (contentMatch.min > targetMatch.min && contentMatch.max < targetMatch.max) {
						// Completely contained within
						length = targetMatch.max - targetMatch.min;
						distanceToElement = length;
						startingPosition = targetMatch.min - canvas.start;
					} else if (contentMatch.min < targetMatch.min && contentMatch.max > targetMatch.max) {
						// Completely contains
						length = contentMatch.max - contentMatch.min;
						distanceToElement = length;
						startingPosition = contentMatch.min - canvas.start;
					} else if (contentMatch.min < targetMatch.min) {
						// To left of
						length = targetMatch.max - contentMatch.min;
						distanceToElement = targetMatch.min - contentMatch.max;
						startingPosition = contentMatch.min - canvas.start;
					} else {
						// To right of
						length = contentMatch.max - targetMatch.min;
						distanceToElement = contentMatch.min - targetMatch.max;
						startingPosition = targetMatch.min - canvas.start;
					}
				} else {
					length = targetMatch.max - targetMatch.min;
				}

				// Snap to UI position
				var targetSnap = targetMatch.point - canvas.min;
				var uiPositionSnap;
				if (contentMatch.size) {
					uiPositionSnap = uiPosition[positionIdentifier] + contentMatch.size + canvas.minMargin;
				} else {
					uiPositionSnap = uiPosition[positionIdentifier] + canvas.minMargin;
				}
				var uiPositionDiff = targetSnap - uiPositionSnap;
				if($(target).hasClass('flowPageMargins') && this.getExtraCanvasMargins) {
					let extraMargins = this.getExtraCanvasMargins();
					if(side === 'Min') {
						uiPositionDiff += extraMargins;
					} else if(side === 'Max') {
						uiPositionDiff -= extraMargins;
					}
				}
				if(Math.abs(uiPositionDiff) < 0.01) {
					uiPositionDiff = 0;
				}

				// When snapping off we will reach here since rect has not been updated yet
				if (Math.abs(uiPositionDiff) < $.FlowLayoutAlignmentLines.SNAP_DISTANCE  ||
					(containWithin && ((side == 'Max' && uiPositionDiff < 0) || (side == 'Min' && uiPositionDiff > 0)))  ||
					(options.containWithinMax && side =='Max' && uiPositionDiff < 0) ||
					(options.containWithinMin && side =='Min' && uiPositionDiff > 0)) {
					alignmentLine.css(lengthIdentifier, (length + 1) + 'px');
					alignmentLine.css(minIdentifier, startingPosition);

					// To keep line visually inside
					if (side == 'Max' || side === 'CenterMax') {
						targetSnap--;
					}
					alignmentLine.css(positionIdentifier, targetSnap + 'px');

					var uiIdentifier = positionIdentifier;
					if(options.uiIdentifier == 'size') {
						uiIdentifier = sizeIdentifier;
					}

					var preferredSide = side;
					if(preferredSide.includes('Min') || preferredSide.includes('Max')) {
						preferredSide = preferredSide.substr(0, 3);
					}
					
					var results = {
						line: alignmentLine,
						uiIdentifier: uiIdentifier,
						uiDiff: uiPositionDiff,
						dataPrefix: dataPrefix,
						vertical: vertical,
						length: length,
						side: side,
						// Helper so that stuff like MinMax which matches our min against somethings max, we can check that we are on OUR Min
						preferredSide: preferredSide,
						target: target,
						distanceToElement: distanceToElement
					};

					if((containWithin && ((side == 'Max' && uiPositionDiff < 0) || (side == 'Min' && uiPositionDiff > 0))) || (options.containWithinMax && side =='Max' && uiPositionDiff < 0) || (options.containWithinMin && side =='Min' && uiPositionDiff > 0)) {
						results.containWithin = true;
					}

					return results;
				}
			}

			if (alignmentLine && alignmentLine.isAttached()) {
				alignmentLine.remove();
				this.alignmentLines = this.alignmentLines.not(alignmentLine);
				$(target).data(dataPrefix + 'Alignment', null);
			}

			return null;
		},

		alignmentLines: $()
	});
};

$.FlowLayoutAlignmentLines.SNAP_DISTANCE = 4;