$.LayoutPage = function(settings) {
	var obj = new $.FlowPageClass(null, $.extend({
		id: 'layout',
		type: 'layout',
		getSubjects: function() {
			if(this.pageSet && this.pageSet.subjects) {
				var subjects = $.merge([], this.pageSet.subjects);

				if(this.pageSet.placeholderSubjects) {
					return $.merge(subjects, this.pageSet.placeholderSubjects);
				} else {
					return subjects;
				}
			} else {
				return null;
			}
		},
		hasPrimarySubject: function() {
			return true;
		},
		getTitle: function() {
			return null;
		},
		updatePageLabel: function () {
			var sidebar = [];

			// this.theme is for the sheet or if a normal layout the outer layout
			if(this.type !== 'Sheet' || this.showSheet) {
				if(this.theme) {
					if(this.theme.Background || (this.pageSet && this.pageSet.projectBackgroundId)) {
						sidebar.push({
							name: 'Backgrounds',
							icon: 'setting',
							popup: 'Change settings on backgrounds',
							onClick: function (page, layout) {
								layout.openThemeSettings();
							}
						});
					}

					sidebar.push({
						name: 'Remove Background',
						icon: 'picture',
						onClick: function (page, layout) {
							layout.clearBackground();
						}
					});
				}
			} else {
				if(this.layoutTheme) {
					if(this.layoutTheme.Background) {
						sidebar.push({
							name: 'Backgrounds',
							icon: 'setting',
							popup: 'Change settings on backgrounds',
							onClick: function (page, layout) {
								layout.openThemeSettings();
							}
						});
					}

					sidebar.push({
						name: 'Remove Background',
						icon: 'picture',
						onClick: function (page, layout) {
							layout.clearBackground();
						}
					});
				}
			}

			var editName = 'Edit Layout';
			if(this.type == 'Sheet') {
				editName = 'Edit Sheet';
			}

			if(this.pageNumber <= 1) {
				sidebar.push({
					name: editName,
					icon: 'settings',
					onClick: function(page, layout) {
						page.editLayout(layout);
					}
				});
			} else {
				sidebar.push({
					name: 'Remove Back',
					icon: 'remove',
					onClick: function(page, layout) {
						page.deleteLayout(layout);
					}
				});
			}

			if(sidebar.length) {
				this.pageLabel = {
					display: '',
					popup: 'Options',
					sidebar: sidebar
				};
			} else {
				this.pageLabel = null;
			}
		},
		editLayout: function(flowLayout) {
			var settings = [];

			if(this.type == 'Sheet') {
				$.merge(settings, [
					{
						name: 'sheet',
						group: 'sheet',
						type: 'section',
						description: 'Sheet Dimensions (in)',
						value: {
							width: this.layout.sheet.width,
							height: this.layout.sheet.height
						},
						settings: [
							{
								id: 'width',
								description: 'Width',
								type: 'positiveFloat',
								range:[0, 100],
								value: this.layout.sheet.width
							},
							{
								id: 'height',
								description: 'Height',
								type: 'positiveFloat',
								range:[0, 100],
								value: this.layout.sheet.height
							}
						]
					},
					{
						name: 'sheetMargin',
						group: 'sheetMargin',
						type: 'section',
						value: $.extend(true, {}, this.layout.sheet.bleed),
						perLine: 4,
						settings: [
							{
								id: 'left',
								description: 'Left Margin',
								type: 'positiveFloat',
								range:[0, 100],
								value: this.layout.sheet.bleed.left
							},
							{
								id: 'right',
								description: 'Right Margin',
								type: 'positiveFloat',
								range:[0, 100],
								value: this.layout.sheet.bleed.right
							},
							{
								id: 'top',
								description: 'Top Margin',
								type: 'positiveFloat',
								range:[0, 100],
								value: this.layout.sheet.bleed.top
							},
							{
								id: 'bottom',
								description: 'Bottom Margin',
								type: 'positiveFloat',
								range:[0, 100],
								value: this.layout.sheet.bleed.bottom
							}
						]
					}
				]);
			}

			var layoutSettings = [
				{
					id: 'width',
					description: 'Width',
					type: 'positiveFloat',
					range:[0, 100],
					value: this.layout.grid.width
				},
				{
					id: 'height',
					description: 'Height',
					type: 'positiveFloat',
					range:[0, 100],
					value: this.layout.grid.height
				}
			];
			var layoutValues = {
				width: this.layout.grid.width,
				height: this.layout.grid.height
			};
			if(this.type == 'Sheet') {
				$.merge(layoutSettings, [
					{
						id: 'columns',
						description: '%value% columns',
						maxDisplay: '10',
						type: 'inc/dec',
						range: [1, 10],
						value: parseInt(this.layout.grid.columns || 4)
					},
					{
						id: 'rows',
						description: '%value% rows',
						maxDisplay: '100',
						type: 'inc/dec',
						range: [1, 100],
						value: parseInt(this.layout.grid.rows || 4)
					},
				]);
				$.extend(layoutValues, {
					columns: parseInt(this.layout.grid.columns || 4),
					rows: parseInt(this.layout.grid.rows || 4)
				});
			} else if(this.type === 'Basic' && this.pageSet?.pages.length > 1) {
				layoutSettings.push({
					id: 'flipBackSideRender',
					description: 'Render back side upside down',
					type: 'checkbox',
					value: this.layout.flipBackSideRender ?? false
				});
			}

			$.merge(settings, [
				{
					name: 'layout',
					group: 'layout',
					subGroup: 'layout',
					type: 'section',
					description: 'Layout Dimensions (in)',
					value: layoutValues,
					settings: layoutSettings,
					perLine: 2
				}
			]);

			if(this.type == 'Sheet') {
				var fields = this.pageSet.getTemplateFields() || [];
				['First Name', 'Last Name', 'Teacher', 'Grade', 'Home Room', 'Student ID'].forEach(field => {
					if(!fields.includes(field)) {
						fields.push(field);
						fields.sort();
					}
				});
				var sortByFields = $.merge([], fields);
				sortByFields.push('Primary Pose Name');

				var horizontalSpacing = parseFloat(this.layout.sheet.spacing ? this.layout.sheet.spacing.horizontal : 0);
				var verticalSpacing = parseFloat(this.layout.sheet.spacing ? this.layout.sheet.spacing.vertical : 0);

				var sortOrder = this.layout.sheet.sortOrder;
				if(!sortOrder) {
					if(this.layout.sheet.stackSort) {
						sortOrder = 'Stack Sort';
					} else {
						sortOrder = 'Standard';
					}
				}

				$.merge(settings, [
					{
						name: 'layoutSpacing',
						group: 'layoutSpacing',
						subGroup: 'layoutSpacing',
						type: 'section',
						value: {
							horizontal: horizontalSpacing,
							vertical: verticalSpacing
						},
						settings: [
							{
								id: 'horizontal',
								description: 'Horizontal Spacing',
								type: 'positiveFloat',
								range:[0, 100],
								value: horizontalSpacing
							},
							{
								id: 'vertical',
								description: 'Vertical Spacing',
								type: 'positiveFloat',
								range:[0, 100],
								value: verticalSpacing
							}
						]
					},
					{
						name: 'grouping',
						description: 'Subjects',
						type: 'section',
						settings: [
							{
								id: 'groupBy',
								description: 'Group By',
								type: 'dropdown',
								options: $.merge(['Project', 'Subject'], fields),
								value: this.layout.sheet.groupBy || 'Project',
								multiple: true,
								saveAll: true
							},
							{
								id: 'sortBy',
								description: 'Sort By',
								type: 'dropdown',
								options: sortByFields,
								value: this.layout.sheet.sortBy || 'Last Name,First Name',
								multiple: true,
								saveAll: true
							},
							{
								id: 'duplicateSubjects',
								description: 'Duplicate each subject %value% times',
								type: 'inc/dec',
								range: [1, 100],
								minDisplay: '1',
								maxDisplay: '100',
								value: this.layout.sheet.duplicateSubjects || 1
							},
							{
								id: 'onePagePerPose',
								description: 'Render each subject once for each pose',
								type: 'checkbox',
								value: this.layout.sheet.onePagePerPose ?? false
							},
							{
								id: 'maxPosesPerSubject',
								description: 'Maximum number of poses for each subject',
								help: 'Leave blank if you want to render each pose for each subject no matter how many there are',
								type: 'positiveInt',
								value: this.layout.sheet.maxPosesPerSubject ?? '',
								customRule: ''
							},
							{
								id: 'sortOrder',
								description: 'Sort Order',
								type: 'dropdown',
								options: [
									'Standard',
									'Reverse Sort',
									// NOTE: Do not try to copy this to a new system - this should just be Column Sort and the current Column Sort should be called something different like alt column stack sort
									'Standard Column Sort',
									'Column Sort',
									'Reverse Column Sort',
									'Stack Sort',
									'Column Stack Sort'
								],
								value: sortOrder
							},
							{
								id: 'includeSubjectsWithoutPhotos',
								description: 'Include subjects without photos',
								type: 'checkbox',
								value: this.layout.sheet.includeSubjectsWithoutPhotos
							}
						]
					}
				]);
			}

			var me = this;
			$.SettingsBuilderDialog(settings, {
				title: 'Edit Details',
				onSettingsApplied: function(settings) {
					me.applyLayoutSettings(settings);
				},
				onSettingChange: $.FlowLayoutSheetUtils.updateSheetLayoutDimensionsFromChange,
				onSettingsHidden: function() {
					flowLayout.refreshPage();
				}
			})[0].settingsForm;
		},
		applyLayoutSettings: function(settings) {
			this.pageSet.pages.forEach(function(page) {
				page.applyLayoutSettingsForPage(settings);
			});
		},
		applyLayoutSettingsForPage: function(settings) {
			const layout = $.extend(true, {}, this.layout.grid, {}, settings.layout);
			delete layout.flipBackSideRender;
			this.setProperty('grid', $.extend(true, {}, this.layout.grid, {}, settings.layout));
			this.layout.grid = this.grid;
			if(settings.layout.flipBackSideRender !== undefined) {
				this.setProperty('flipBackSideRender', settings.layout.flipBackSideRender);
				this.layout.flipBackSideRender = settings.layout.flipBackSideRender;
			}

			if(settings.sheet) {
				this.setProperty('sheet', $.extend(true, {}, this.layout.sheet, {
					bleed: settings.sheetMargin,
					spacing: settings.layoutSpacing,
					sortBy: settings.sortBy,
					groupBy: settings.groupBy,
					duplicateSubjects: settings.duplicateSubjects,
					onePagePerPose: settings.onePagePerPose,
					maxPosesPerSubject: settings.maxPosesPerSubject,
					sortOrder: settings.sortOrder,
					includeSubjectsWithoutPhotos: settings.includeSubjectsWithoutPhotos
				}, settings.sheet));
				this.layout.sheet = this.sheet;
			}
		},
		deleteLayout: function() {
			this.pageSet.removePage(this);
		},
		getExtraTextEditTools: function() {
			return [
				{
					addClass: 'icon',
					updateDisplayAll: true,
					group: ['upper case', 'scan subjects', 'dynamic text fields']
				}
			];
		},
		getProjectPageSet: function(subjects) {
			if(typeof subjects === 'undefined') {
				subjects = this.getSubjects();
			}
			if(!subjects) {
				return this.pageSet;
			}

			if(!$.isTruthy(this.layout.sheet.includeSubjectsWithoutPhotos)) {
				subjects = subjects.filter(function(subject) {
					return !!subject.yearbookPhoto;
				});
			}

			var groupBy = this.layout.sheet.groupBy || 'Project';
			var sortBy = this.layout.sheet.sortBy || 'Last Name,First Name';

			let passedGroupBy = groupBy === 'Subject' ? 'Project' : groupBy;
			var batches = $.SubjectManagement.recreateBatchesFromFilter({
				subjects: subjects,
				filter: passedGroupBy + ' and sort by ' + sortBy,
				addAppSpecificSubjectsData: false,
				remoteCall: false,
				filterByTeacherGrade: false
			});
			if(groupBy === 'Subject' || groupBy.startsWith('Subject,')) {
				let sortedSubjects = batches.map(b => b.subjects).flat();
				batches = sortedSubjects.map(subject => ({
					name: subject.id,
					subjects: [subject]
				}));
			}

			var sheet = new $.FlowLayoutSheet({});
			sheet.page = this;
			var subjectsPerSheet = sheet.getSubjectsInSheet(this.layout);
			var subjectGrid = $.FlowLayoutSheetUtils.getSubjectGrid(this.layout);

			var pages = [];
			var pageNumber = 1;

			var sortOrder = this.layout.sheet.sortOrder;
			if(!sortOrder) {
				if(this.layout.sheet.stackSort) {
					sortOrder = 'Stack Sort';
				} else {
					sortOrder = 'Standard';
				}
			}

			var me = this;
			var subjectsTotal = 0;
			batches.forEach(function(batch) {
				var subjects = batch.subjects;
				if(!subjects.length) {
					return;
				}
				var batchPageNumber = 1;

				subjects = $.FlowLayoutSheetUtils.getSheetSubjects(subjects, {
					definition: me.layout
				});

				var pagesInBatch = Math.ceil(subjects.length / subjectsPerSheet);
				for(let subjectIteratorIndex = 0; subjectIteratorIndex < subjects.length; subjectIteratorIndex += subjectsPerSheet) {
					let page = new $.LayoutPage(me);

					var pageSubjects = [];
					for(let pageSubjectIndex = 0; pageSubjectIndex < subjectsPerSheet; pageSubjectIndex++) {
						var pageSubject;
						var subjectIndex = 0;
						if(sortOrder === 'Stack Sort') {
							subjectIndex = pageSubjectIndex * pagesInBatch + (batchPageNumber - 1);
						} else if(sortOrder === 'Standard Column Sort') {
							let subjectsInColumn = subjectGrid.rows;
							let column = pageSubjectIndex % subjectGrid.columns;
							let row = Math.floor(pageSubjectIndex / subjectGrid.columns);
							subjectIndex = subjectIteratorIndex + column * subjectsInColumn + row;
						} else if(sortOrder === 'Column Sort') {
							let subjectsInColumn = subjectGrid.rows * pagesInBatch;
							let column = pageSubjectIndex % subjectGrid.columns;
							let row = Math.floor(pageSubjectIndex / subjectGrid.columns) + (batchPageNumber - 1) * subjectGrid.rows;
							subjectIndex = column * subjectsInColumn + row;
						} else if(sortOrder === 'Reverse Column Sort') {
							let subjectsInColumn = subjectGrid.rows;
							let column = pageSubjectIndex % subjectGrid.columns;
							let row = Math.floor(pageSubjectIndex / subjectGrid.columns);
							subjectIndex = subjectIteratorIndex + (subjectsPerSheet - (column * subjectsInColumn + row) - 1);
						} else if(sortOrder === 'Column Stack Sort') {
							let column = pageSubjectIndex % subjectGrid.columns;
							let row = Math.floor(pageSubjectIndex / subjectGrid.columns);
							subjectIndex = row * pagesInBatch + (batchPageNumber - 1) + (column * subjectGrid.rows * pagesInBatch);
						} else if(sortOrder === 'Reverse Sort') {
							let row = Math.floor(pageSubjectIndex / subjectGrid.columns);
							let column = pageSubjectIndex % subjectGrid.columns;
							subjectIndex = subjectIteratorIndex + (subjectGrid.columns - column - 1) + row * subjectGrid.columns;
						} else {
							subjectIndex = subjectIteratorIndex + pageSubjectIndex;
						}
						pageSubject = subjects[subjectIndex];

						if(pageSubject) {
							pageSubjects.push($.extend(true, {
								sequenceNumber: (subjectIndex + 1 + subjectsTotal) + ''
							}, pageSubject));
							subjectIndex++;
						} else if(['Standard Column Sort', 'Column Sort', 'Column Stack Sort', 'Reverse Sort', 'Reverse Column Sort'].includes(sortOrder)) {
							pageSubjects.push(null);
						}
					}

					page.subjects = pageSubjects;
					page.getSubjects = function() {
						return this.subjects;
					};
					pageSubjects.filter(s => !!s)[0].groupCount = subjects.length;
					// Breaks first subject of each page in preview (possibly only on column stack sort?)
					// page.hasPrimarySubject = null;
					page.pageNumber = pageNumber++;
					batchPageNumber++;
					pages.push(page);
				}

				subjectsTotal += subjects.length;
			});
			
			return $.FlowPageSubSet(this.pageSet, pages);
		},

		hasOrderData: function() {
			return JSON.stringify(this.candids).includes('ordered photo ') || JSON.stringify(this.texts).includes('%order ');
		},

		getPageMargins: null,
		setPageMargins: null,
		title: null,
		canHaveBarcodes: true,
		disableRotateSwapWidthAndHeight: true,
		showSheet: false
	}, settings));

	if(obj.layout?.grid) {
		if(!obj.layout.grid.bleed) {
			$.extend(obj.layout.grid, {
				bleed: 0
			});
		}

		obj.layout.grid.bleedOutsideDimensions = true;
	}

	if($.isArray(obj.layout.images)) {
		obj.setCandidArray(obj.layout.images);
	} else if($.isPlainObject(obj.layout.images)) {
		obj.candids = obj.layout.images;
	}
	if($.isArray(obj.layout.texts)) {
		obj.setTextArray(obj.layout.texts);
	} else if($.isPlainObject(obj.layout.texts)) {
		obj.texts = obj.layout.texts;
	}

	if(obj.layout.theme) {
		obj.theme = obj.layout.theme;
	}
	if(obj.layout.layoutTheme) {
		obj.layoutTheme = obj.layout.layoutTheme;
	}

	// If we ever load an empty object PHP serialize it as an array
	if($.isArray(obj.layout.extras)) {
		obj.extras = obj.layout.extras = {};
	}

	if(typeof obj.layout.sheet?.includeSubjectsWithoutPhotos === 'string') {
		obj.layout.sheet.includeSubjectsWithoutPhotos = obj.layout.sheet.includeSubjectsWithoutPhotos === 'true';
	}

	// Delete so $.FlowLayout doesn't auto add them from layout definition
	delete obj.layout.images;
	delete obj.layout.texts;
	delete obj.layout.theme;
	delete obj.layout.layoutTheme;

	return obj;
};