$.PageRender = function(renderTargets, options) {
	var obj = new Object;

	if(!options) {
		options = {};
	}
	if(options.isDemo) {
		options.watermarkText = 'DEMO COPY';
	}

	$.extend(obj, {
		generatePages: function (onCompletion) {
			var loadText;
			if (this.dialog) {
				loadText = this.dialog.find('.text.loader');
			} else {
				loadText = $('#pageLoading').find('.text.loader');
			}
			var details = {
				renders: 0
			};

			var chain = new $.ExecutionChain(function () {
				onCompletion.call(obj, details);
			}, {
				onProgress: function (complete, total) {
					if (complete > 1) {
						loadText.text('Generating ' + (complete - 1) + ' of ' + (total - 1) + ' total pages');
					}
				}
			});

			var documentDimensions = this.getDocumentDimensions();
			var documentInchWidth = documentDimensions.inchWidth;
			var documentInchHeight = documentDimensions.inchHeight;

			var me = this;
			this.pageTitles = [];
			this.batchIds = [];
			var addPage = function (renderTarget, pageIndexes, largeCellUserIndex) {
				if(!$.isArray(pageIndexes)) {
					pageIndexes = [pageIndexes];
				}

				try {
					var outerWrapper = $('<div class="contentPreview noMarginBorders">').appendTo('body');
					var innerWrapper = $('<div class="contentProduction">').appendTo(outerWrapper);

					var totalRenderedPageWidth = 0;
					for(let i = 0; i < pageIndexes.length; i++) {
						var pageIndex = pageIndexes[i];
						var pageSet = renderTarget.pageSet;
						var side = (pageIndex + me.pageOffset) % 2 == 0 ? 'Left' : 'Right';
						if(me.forceSide) {
							side = me.forceSide;
						}
						
						var constructor = options.flowLayoutConstructor || $.FlowLayout;
						var contentPage = new constructor($.extend({
							side: side,
							editable: false,
							pageSet: pageSet,
							fixedSize: true,
							showLabels: false,
							displayCropMarks: me.displayCropMarks,
							includeWhiteSpace: me.production
						}, options.flowLayoutOptions));
						contentPage.errorOnMissingSubjects = true;

						// Setting container editable sets bleed mask visible
						if(me.showBleedColor) {
							contentPage.container.setEditable(true);
						}
						if(me.hideBleed) {
							contentPage.hideBleed = me.hideBleed;
						}

						var inchWidth = documentInchWidth;
						var inchHeight = documentInchHeight;
						var page = pageSet.getPage(pageIndex);
						// Fix error where page gets dynamically deleted during render
						if(!page) {
							return;
						}

						var layout = page.getLayout({
							includeWhiteSpace: me.production
						});
						if(layout && layout.grid) {
							var grid = layout.grid;
							if(layout.sheet) {
								grid = layout.sheet;
							}

							if(grid.width) {
								inchWidth = parseFloat(grid.width);
							}
							if(grid.height) {
								inchHeight = parseFloat(grid.height);
							}
						}
						renderTarget.pageWidth = Math.max(renderTarget.pageWidth, inchWidth);
						renderTarget.pageHeight = Math.max(renderTarget.pageHeight, inchHeight);
						renderTarget.totalRenderWidth = renderTarget.pageWidth * renderTarget.layoutsPerPage;
						renderTarget.totalRenderHeight = renderTarget.pageHeight;

						var dimId = (inchWidth.toFixed(4) + 'x' + inchHeight.toFixed(4)).replace(/\./ig, '');
						if(!renderTarget.pageDimensions) {
							renderTarget.pageDimensions = {};
						}
						if(!renderTarget.pageDimensions[dimId]) {
							renderTarget.pageDimensions[dimId] = {
								width: inchWidth,
								height: inchHeight
							}
						}

						var pageWidth = (inchWidth * obj.ratio).toFixedDown(2);
						var pageHeight = (inchHeight * obj.ratio).toFixedDown(2);
						$(contentPage).addClass('hiddenRender').appendTo(innerWrapper).css({
							width: pageWidth,
							height: pageHeight
						});

						if(page.getRootPage && page.type == 'classOverflow' && !page.getKids()) {
							var rootPage = page.getRootPage();
							var startSide = contentPage.side;
							while(page != rootPage && rootPage && rootPage.getOverflowPage && rootPage.getPageNumber() < page.getPageNumber()) {
								// Side matters, so make sure the previous layouts get put in the right slot
								var rootPageSide = (pageIndex + me.pageOffset - (page.getPageNumber() - rootPage.getPageNumber())) % 2 == 0 ? 'Left' : 'Right';
								contentPage.setSide(rootPageSide);

								contentPage.setPage(rootPage);
								rootPage = rootPage.getOverflowPage();
							}

							// Put start side back
							contentPage.setSide(startSide);
						}

						contentPage.setPage(page);

						var subjectIndexes = contentPage.getSubjectIndexes();
						for(var id in subjectIndexes) {
							var subjectIndex = subjectIndexes[id];

							// In background render this is wrong because renderIndex is just the pageNumber
							if(!me.saveSubjectIndex) {
								subjectIndex.images.forEach(function(image) {
									image.page = renderTarget.renderIndex + 1;
								});
							}

							if(renderTarget.subjectIndex[id]) {
								$.merge(renderTarget.subjectIndex[id].images, subjectIndex.images);
							} else {
								renderTarget.subjectIndex[id] = subjectIndex;
							}
						}

						var title;
						if(page.getTitleText) {
							title = page.getTitleText();
						} else if (page.title) {
							title = page.title;
						} else if (page.classObj) {
							title = page.classObj.name;
						}
						if ($.isInit(largeCellUserIndex)) {
							contentPage.setLargeCellUser(largeCellUserIndex);
							try {
								var largeCellUser = page.getKids()[largeCellUserIndex];
								title += ' - ' + largeCellUser['Last Name'] + ', ' + largeCellUser['First Name'];
							} catch (e) {
								console.error('Failed to grab large cell user');
							}
						}
						me.pageTitles.push(title);
						if(page.classObj) {
							me.batchIds.push(page.classObj.id);
						}
						var content = contentPage.outputProductionPage();
						$(content).find('.hiddenRender').removeClass('hiddenRender');
						if (content.attr('renderedEffects')) {
							if (!details.renderedEffects) {
								details.renderedEffects = 0;
							}

							details.renderedEffects += parseInt(content.attr('renderedEffects'));
							content.removeAttr('renderedEffects');
						}
						if(me.saveSubjectIndex) {
							content.attr('subjectIndex', JSON.stringify(subjectIndexes));
						}
						totalRenderedPageWidth += pageWidth;
					}

					// Fix width of parent if setPage changed
					if(renderTarget.layoutsPerPage > 1) {
						$(innerWrapper).css('width', totalRenderedPageWidth);
					}

					if ($.getGETParams().debugPrinceCombo) {
						$('body > .pusher, #header, .modals').remove();
						$('body').css('overflow', 'visible');
						// eslint-disable-next-line
						asdf;
					}

					innerWrapper.addClass('dim' + dimId);
					renderTarget.allPages += innerWrapper[0].outerHTML;
					renderTarget.renderIndex++;
					outerWrapper.remove();
				} catch (e) {
					console.error('Failed to render: ', e);

					if(e && e.indexOf && e.indexOf('Missing subjects on ') !== -1) {
						me.handleError('Missing subjects in render for page ' + page.getPageReference() + '. Please view that page to make sure all subjects are in the page. If you continue to get this error, please try to refresh the page.');
					} else {
						$.Alert('Error', 'Failed to render');
					}

					throw e;
				}
			};

			// This needs to be on a switch
			for(let i in this.renderTargets) {
				let renderTarget = this.renderTargets[i];
				var pageSet = renderTarget.pageSet;
				renderTarget.allPages = '';
				renderTarget.pageWidth = 0;
				renderTarget.pageHeight = 0;
				renderTarget.subjectIndex = {};
				renderTarget.renderIndex = 0;

				var inc = 0;
				// TODO: Why is this re-using i...
				for (i = 0; i < pageSet.getTotalPages() / renderTarget.layoutsPerPage; i++) {
					var pageIndex;
					if (this.specialPages && this.specialPages[i]) {
						var special = this.specialPages[i];
						pageIndex = special.page;

						inc++;
					} else {
						pageIndex = i + inc;
					}

					var page = pageSet.getPage(pageIndex);
					if (page.getNextIndividualIndex && page.isIndividualizedLayout() && this.production) {
						if(this.individualizedIndexes) {
							for (i = 0; i < this.individualizedIndexes.length; i++) {
								let userIndex = this.individualizedIndexes[i];
								chain.add(addPage, [renderTarget, pageIndex, userIndex]);
								details.renders++;
							}
						} else if(page.classObj && page.classObj.individualizedIndexes) {
							for (i = 0; i < page.classObj.individualizedIndexes.length; i++) {
								let userIndex = page.classObj.individualizedIndexes[i];
								chain.add(addPage, [renderTarget, pageIndex, userIndex]);
								details.renders++;
							}
						} else {
							var index = page.getNextIndividualIndex(-1);
							while (index !== null) {
								chain.add(addPage, [renderTarget, pageIndex, index]);
								details.renders++;
								index = page.getNextIndividualIndex(index);
							}
						}
					} else if(renderTarget.layoutsPerPage > 1) {
						var pageIndexes = [pageIndex];
						for(var j = 1; j < renderTarget.layoutsPerPage; j++) {
							pageIndexes.push(pageSet.getTotalPages() - pageIndex - 1);
						}
						chain.add(addPage, [renderTarget, pageIndexes]);
						details.renders++;
					} else {
						chain.add(addPage, [renderTarget, pageIndex]);
						details.renders++;
					}
				}
			}
			chain.done();
		},
		getDocumentDimensions: function() {
			var documentInchWidth = $.PAGE_WIDTH;
			var documentInchHeight = $.PAGE_HEIGHT;
			for(let i in this.renderTargets) {
				var pageSet = this.renderTargets[i].pageSet;

				if (pageSet.getLayout) {
					var layout = pageSet.getLayout({
						includeWhiteSpace: this.production
					});
					if (layout && layout.grid && layout.grid.width) {
						documentInchWidth = layout.grid.width;
						documentInchHeight = layout.grid.height;
					}
				} else if(pageSet.getLayoutDimensions && pageSet.getLayoutDimensions()) {
					// Side doesn't matter because we want total width/height
					var outerDimensions = pageSet.getOuterDimensions('Right', {
						includeWhiteSpace: this.production
					});
					documentInchWidth = outerDimensions.width;
					documentInchHeight = outerDimensions.height;

					if(this.hideBleed === true) {
						documentInchWidth = documentInchWidth - outerDimensions.bleed.left - outerDimensions.bleed.right + outerDimensions.safeSpace.left + outerDimensions.safeSpace.right;
						documentInchHeight = documentInchHeight - outerDimensions.bleed.top - outerDimensions.bleed.bottom + outerDimensions.safeSpace.top + outerDimensions.safeSpace.bottom;
					}
				}

				break;
			}

			return {
				inchWidth: documentInchWidth,
				inchHeight: documentInchHeight
			};
		},
		generateHeadHTML: function(renderTarget) {
			var rootStyle = this.generateRootStyle(renderTarget);

			let webpackStyles = '<link rel="stylesheet" type="text/css" href="' + window.location.origin + '/vue/css/chunk-common.css"/>';

			return webpackStyles +
				(window.blockRemoteFonts ? '<!--INSERT FONT STYLES HERE-->' : $.GoogleFontLink) +
				'<style>' + rootStyle + '</style>';
		},
		generateRootStyle: function(renderTarget) {
			var rootStyle = '@page { margin: 0px; prince-jpeg-quality: 100%;}';
			for(var id in renderTarget.pageDimensions) {
				var dimensions = renderTarget.pageDimensions[id];
				rootStyle += '@page dim' + id + ' { size: ' + (dimensions.width * renderTarget.layoutsPerPage) + 'in ' + dimensions.height + 'in; }';
				rootStyle += 'div.dim' + id + ' { page: dim' + id + ' }';
			}
			
			if(this.production) {
				rootStyle += '@prince-pdf {prince-filter-resolution: 400dpi}';

				if(this.watermarkText) {
					rootStyle += '@page {@prince-overlay {color: rgba(255,0,0,0.15); content: "' + this.watermarkText + '"; font-size: 80pt; z-index: 1000000; transform: rotate(-45deg);} }'
				}
			} else {
				rootStyle += '@prince-pdf {prince-filter-resolution: 200dpi}';
				rootStyle += '@page {@prince-overlay {color: rgba(255,0,0,0.15); content: "PREVIEW COPY"; font-size: 80pt; z-index: 1000000; transform: rotate(-45deg);} }'
			}

			return rootStyle;
		},
		generateBodyHTML: function(renderTarget) {
			var html = renderTarget.allPages;
			var char = String.fromCharCode(8203);
			var index = html.indexOf(char);
			while (index != -1) {
				html = html.replace(char, '&#8203;');
				index = html.indexOf(char);
			}

			// Remove more bogus characters
			// According to https://en.wikipedia.org/wiki/Private_Use_Areas U+F0000–U+FFFFD, U+100000–U+10FFFD are both unused characters
			// eslint-disable-next-line no-control-regex
			html = html.replace(/[\u200E\u2028\u0080-\u009f\u0000-\u001f\u{F0000}-\u{FFFFD}\u{100000}-\u{10FFFD}]/gu, '').replace(/[\uE000-\uF8FF]/g, '');

			// Chrome puts in drop-shadow as (rgb... 1px `1px 1px) and Prince doesn't like that
			var matchColors = /drop-shadow\(rgb\((\d{1,3}), (\d{1,3}), (\d{1,3})\)(.*?)\)/ig;
			html = html.replace(matchColors, function(match, rgb1, rgb2, rgb3, lengths) {
				return 'drop-shadow(' + lengths + ' rgb(' + rgb1 + ', ' + rgb2 + ', ' + rgb3 + '))';
			});

			// PhantomJS really likes -webkit- but Prince doesn't
			html = html.replace(/-webkit-/g, '');

			return html;
		},
		generateHTML: function (renderTarget) {
			return '<html><head>' + this.generateHeadHTML(renderTarget) + '</head><body>' + this.generateBodyHTML(renderTarget) + '</body></html>';
		},
		generatePDF: function (details, onCompletion) {
			var me = this;
			var ajaxData = [];
			var chain = new $.ExecutionChain(function() {
				if(this.errorCount > 0) {
					me.handleError();
				} else if (ajaxData[0].worker && !me.completeAfterWorkerStarts) {
					me.dialog.find('.text.loader').text('Rendering pages.  This process may take a few minutes.');
					me.waitForWorker(ajaxData[0], onCompletion);
				} else {
					onCompletion(ajaxData[0]);
				}
			});

			for(let i in this.renderTargets) {
				let renderTarget = this.renderTargets[i];
				if(renderTarget.waitFor) {
					renderTarget.waitFor.forEach(function(waitFor) {
						waitFor.waitForMe = renderTarget;
					});
				}
			}

			for(let i in this.renderTargets) {
				let renderTarget = this.renderTargets[i];
				if(renderTarget.waitFor) {
					continue;
				}
				this.addPrintAjax(chain, renderTarget, details, ajaxData);
			}
			chain.done();
		},
		addPrintAjax: function(chain, renderTarget, details, ajaxData) {
			var html = renderTarget.html;

			// Break into chunks since PHP seems to not be able to decode strings past a certain size
			var chunks = [];
			while (html.length) {
				var chunkLength = Math.min(html.length, 2000000);
				var chunk = html.substr(0, chunkLength);
				chunks.push(btoa(window.pako.deflate(chunk, {to: 'string'})));
				html = html.substr(chunkLength);
			}

			var subjectIndexArray = $.map(renderTarget.subjectIndex, function(value, index) {
				return [value];
			});

			var postData = {
				html: chunks,
				jobId: renderTarget.pageSet.jobId,
				production: this.production,
				batchName: this.batchName,
				subjectIndex: JSON.stringify(subjectIndexArray),
				pages: renderTarget.pageSet.getTotalPages()
			};
			if(this.includePageTitles) {
				postData.pageTitles = this.pageTitles;
			}
			if(this.includeBatchIds) {
				postData.batchIds = this.batchIds;
			}
			if(renderTarget.batchName) {
				postData.batchName = renderTarget.batchName;
			}
			if(renderTarget.waitFor) {
				postData.waitForRenders = renderTarget.waitFor;
			}
			if(renderTarget.waitForMe || this.blockEmail) {
				postData.blockEmail = true;
			}
			if(renderTarget.totalRenderWidth && renderTarget.totalRenderHeight) {
				postData.pageDimensions = JSON.stringify({
					inchWidth: renderTarget.totalRenderWidth,
					inchHeight: renderTarget.totalRenderHeight,
					pixelWidth: (renderTarget.totalRenderWidth * $.PDF_PIXEL_RATIO),
					pixelHeight: (renderTarget.totalRenderHeight * $.PDF_PIXEL_RATIO)
				});
			}
			if(details) {
				$.extend(postData, details);
			}
			if(this.flattenPDF) {
				postData.flattenPDF = this.flattenPDF;
				postData.pageWidth = renderTarget.totalRenderWidth;
				postData.pageHeight = renderTarget.totalRenderHeight;
			}
			if($.getGETParams().debugSplitRenders) {
				postData.flattenPDF = false;
				postData.splitRenders = true;
			}
			if(this.extraPostData) {
				$.extend(postData, this.extraPostData);
			}

			var me = this;
			chain.add({
				url: 'ajax/printPdf.php',
				data: postData,
				dataType: 'json',
				type: 'POST',
				timeout: 40000,
				success: function (data) {
					data.startTime = new Date().getTime();
					ajaxData.push(data);

					if(renderTarget.waitForMe) {
						var updateTo = data.id ? data.id : null;

						var waitFor = renderTarget.waitForMe.waitFor;
						waitFor.forEach(function(waitForPart, index) {
							if(waitForPart == renderTarget) {
								waitFor[index] = updateTo;
							}
						});

						var updated = waitFor.filter(function(waitForPart) {
							return !$.isPlainObject(waitForPart);
						});

						if(updated.length >= waitFor.length) {
							me.addPrintAjax(chain, renderTarget.waitForMe, details, ajaxData);
						}
					}
				},
				error: function () {
					me.handleError();
				}
			});
		},

		backgroundGeneratePDF: function(onCompletion) {
			try {
				var me = this;
				var ajaxData = [];
				var chain = new $.ExecutionChain(function() {
					if(this.errorCount > 0) {
						me.handleError();
					} else if(ajaxData[0].worker && !me.completeAfterWorkerStarts) {
						me.dialog.find('.text.loader').text('Rendering pages.  This process may take a few minutes.');
						me.waitForWorker(ajaxData[0], onCompletion);
					} else {
						// First result is going to be covers, so want last result
						onCompletion(ajaxData[ajaxData.length - 1]);
					}
				});

				var i, renderTarget;
				for(i in this.renderTargets) {
					renderTarget = this.renderTargets[i];
					if(renderTarget.waitFor) {
						renderTarget.waitFor.forEach(function(waitFor) {
							waitFor.waitForMe = renderTarget;
						});
					}
				}

				for(i in this.renderTargets) {
					renderTarget = this.renderTargets[i];
					if(renderTarget.waitFor) {
						continue;
					}
					this.addPrintAjaxBackground(chain, renderTarget, ajaxData);
				}
				chain.done();
			} catch(e) {
				me.handleError(e.message);
			}
		},
		addPrintAjaxBackground: function(chain, renderTarget, ajaxData) {
			var postData = {
				jobId: renderTarget.pageSet.jobId,
				production: this.production,
				batchName: this.batchName,
				pages: renderTarget.pageSet.pages.map(function(page) {
					return page.pageNumber
				})
			};
			if(renderTarget.batchName) {
				postData.batchName = renderTarget.batchName;
			}
			if(this.includeBatches) {
				var myIndividualizedIndexes = this.individualizedIndexes;
				var isProduction = this.production;

				var batchesPageSet = renderTarget.pageSet;
				if(this.includeAllBatches && batchesPageSet.wrapperPageSet) {
					batchesPageSet = batchesPageSet.wrapperPageSet;
				}

				var batches;
				if(this.includeAllBatches) {
					batches = $.merge([], batchesPageSet.classes);

					batches.push({
						id: -1,
						subjects: batchesPageSet.getPlaceholderSubjects()
					});
				} else {
					batches = batchesPageSet.pages.filter(function(page) {
						return page.getClass && page.getClass() && page.getClass().subjects;
					}).map(function(page) {
						var classObj = $.extend(true, {}, page.getClass());
						if(page.getNextIndividualIndex && page.layout && page.isIndividualizedLayout() && isProduction) {
							if(myIndividualizedIndexes) {
								classObj.individualizedIndexes = myIndividualizedIndexes
							} else if(obj.checkProductSKUs && $.isOrderedIndividualizedComposite) {
								let indexes = [];
								var subjects = page.getSubjects();
								for(let i = 0; i < subjects.length; i++) {
									if($.isOrderedIndividualizedComposite(subjects[i])) {
										indexes.push(i);
									}
								}

								classObj.individualizedIndexes = indexes;
							} else {
								let indexes = [];
								var index = page.getNextIndividualIndex(-1);
								while(index !== null) {
									indexes.push(index);
									index = page.getNextIndividualIndex(index);
								}

								classObj.individualizedIndexes = indexes;
							}
						}
						return classObj;
					});
					batchesPageSet.pages.forEach(function(page) {
						if(page.getExtraClasses && page.getExtraClasses().length) {
							$.merge(batches, page.getExtraClasses());
						}
					});
					batches = batches.filter(function(batch, index) {
						return batches.indexOfMatch(batch, 'id') === index;
					});
				}

				if(this.includePageTitles) {
					postData.pageTitles = batchesPageSet.pages.filter(function(page) {
						return page.classObj && page.classObj.subjects;
					}).reduce(function(titles, page, index) {
						var title;
						if(page.getTitleText) {
							title = page.getTitleText();
						} else if (page.title) {
							title = page.title;
						} else if (page.classObj) {
							title = page.classObj.name;
						}

						const batch = batches.find(batch => batch.id === page.classObj.id);
						if(batch?.individualizedIndexes) {
							var subjects = page.getKids();

							batch.individualizedIndexes.forEach(function(individualizedIndex) {
								var largeCellUser = subjects[individualizedIndex];
								titles.push(title + ' - ' + largeCellUser['Last Name'] + ', ' + largeCellUser['First Name']);
							});
						} else  {
							titles.push(title);
						}
	
						return titles;
					}, []);
				}

				if(window.btoa && window.pako) {
					postData.batches = window.btoa(window.pako.deflate(JSON.stringify(batches), {to: 'string'}));
				} else {
					postData.batches = JSON.stringify(batches);
				}
			} else if(this.includePageTitles) {
				postData.pageTitles = renderTarget.pageSet.pages.map(function(page) {
					var title;
					if(page.getTitleText) {
						title = page.getTitleText();
					} else if (page.title) {
						title = page.title;
					} else if (page.classObj) {
						title = page.classObj.name;
					}

					return title;
				});
			}
			if(this.includeBatchIds) {
				postData.batchIds = renderTarget.pageSet.pages.filter(function(page) {
					return page.classObj;
				}).map(function(page) {
					return page.classObj.id;
				});
			}

			if(renderTarget.pageSet && renderTarget.pageSet.projectBackgroundId) {
				postData.projectBackgroundId = renderTarget.pageSet.projectBackgroundId;
			}
			if(renderTarget.pageSet && renderTarget.pageSet.projectBackgroundCdnUrl) {
				postData.projectBackgroundCdnUrl = renderTarget.pageSet.projectBackgroundCdnUrl;
			}
			if(this.watermarkText) {
				postData.watermarkText = this.watermarkText;
			}
			if(renderTarget.waitFor) {
				postData.waitForRenders = renderTarget.waitFor;
			}
			if(renderTarget.waitForMe || this.blockEmail) {
				postData.blockEmail = true;
			}
			if(this.flattenPDF) {
				postData.flattenPDF = this.flattenPDF;
				postData.pageWidth = renderTarget.totalRenderWidth;
				postData.pageHeight = renderTarget.totalRenderHeight;
			}
			if(this.extraPostData) {
				$.extend(postData, this.extraPostData);
			}
			if(renderTarget.extraPostData) {
				$.extend(postData, renderTarget.extraPostData);
			}
			postData.layoutsPerPage = renderTarget.layoutsPerPage;
			if(this.displayCropMarks) {
				postData.displayCropMarks = this.displayCropMarks;
			}
			if(this.showBleedColor) {
				postData.showBleedColor = this.showBleedColor;
			}
			if(this.hideBleed) {
				postData.hideBleed = this.hideBleed;
			}
			if(renderTarget.versionId) {
				postData.versionId = renderTarget.versionId;
			} else if(this.versionId) {
				postData.versionId = this.versionId;
			}
			if(postData.pageTitles) {
				postData.pageTitles.forEach(function(pageTitle, pageTitleIndex) {
					var duplicateIndex = postData.pageTitles.indexOf(pageTitle);
					if(duplicateIndex !== pageTitleIndex) {
						var copyIndex = 1;
						var newPageTitle = pageTitle;
						while(postData.pageTitles.indexOf(newPageTitle) !== -1) {
							newPageTitle = pageTitle + ' (' + copyIndex + ')';
							copyIndex++;
						}

						postData.pageTitles[pageTitleIndex] = newPageTitle;
					}
				});
			}

			var me = this;
			chain.add({
				url: 'ajax/renders.php',
				data: postData,
				dataType: 'json',
				type: 'POST',
				timeout: 40000,
				success: function (data) {
					ajaxData.push(data);

					if(renderTarget.waitForMe) {
						var updateTo = data.remoteName ? data.remoteName : null;

						var waitFor = renderTarget.waitForMe.waitFor;
						waitFor.forEach(function(waitForPart, index) {
							if(waitForPart == renderTarget) {
								waitFor[index] = updateTo;
							}
						});

						var updated = waitFor.filter(function(waitForPart) {
							return !$.isPlainObject(waitForPart);
						});

						if(updated.length >= waitFor.length) {
							me.addPrintAjaxBackground(chain, renderTarget.waitForMe, ajaxData);
						}
					}
				},
				error: function () {
					obj.handleError();
				}
			});
		},

		generatePDFFromPages: function (onComplete) {
			// Delay so if no classes to load, dialog variable is set correctly
			var me = this;
			window.setTimeout(function () {
				if(me.backgroundRender) {
					me.backgroundGeneratePDF(function(data) {
						if(me.completeAfterWorkerStarts) {
							onComplete({
								id: data.remoteName
							});
						} else {
							obj.dialog.find('.text.loader').text('Rendering pages.  This process may take a few minutes.');
							obj.waitForWorker(data, function() {
								onComplete({
									id: data.remoteName
								});
							});
						}
					});
				} else {
					me.generatePages(function (details) {
						for (let i in me.renderTargets) {
							let renderTarget = me.renderTargets[i];
							renderTarget.html = me.generateHTML(renderTarget);
						}

						me.generatePDF(details, function (data) {
							onComplete(data);
						});
					});
				}
			}, 1);
		},

		waitForWorker: function (initData, onCompletion) {
			var me = this;
			var worker = initData.worker || initData.workerToken;
			if (!worker) {
				this.handleError();
			}

			$.waitForWorker(worker, {
				onComplete: function() {
					// If dialog is
					if (me.dialogClosed) {
						return;
					}

					initData.executionTime = (new Date().getTime() - initData.startTime) / 1000;
					onCompletion(initData);
				},
				onError: function() {
					if(me.backgroundRender) {
						$.proxyAjax({
							url: 'ajax/renders.php?renderId=' + initData.renderId,
							success: function(data) {
								me.handleError(data.error);
							},
							error: function() {
								me.handleError();
							}
						});
					} else {
						me.handleError();
					}
				}
			});
		},

		showPDFDialog: function (data) {
			var me = this;
			this.dialog = $('<div class="ui modal"><i class="close icon"></i><div class="header">Generating PDF</div></div>')
				.append('<div class="content" style="text-align: center;"><div class="ui active inverted dimmer"><div class="ui text loader"></div></div></div>')
				.append('<div class="actions"><div class="ui negative button">Close</div></div>')
				.modal({
					onHidden: function () {
						me.dialogClosed = true;
						$(this).remove();
						$('body').css('height', '');
					},
					closable: false
				}).modal('show');

			if(data) {
				this.openPDFPreview(data);
			} else {
				this.generatePDFFromPages(function (data) {
					$.ajax({
						url: 'ajax/getPdf.php',
						data: {
							file: data.id,
							returnUrl: true
						},
						dataType: 'json',
						type: 'GET',
						success: function (val) {
							data.url = val.url;
							me.openPDFPreview(data);
						},
						error: function () {
							me.handleError();
						}
					});
				});
			}
		},
		openPDFPreview: function (data) {
			window.pdfjsLib.GlobalWorkerOptions.workerSrc = '/js/pdf.worker.js';
			var filename = data.id;
			var dialog = this.dialog;
			dialog.find('.header').text((this.production ? 'Production' : 'Preview') + ' PDF');

			var pdf, currentPage = 1, canvases = [], currentLoading = 0, loadAfterDone;
			var loadPDFPreviewPage = function (page) {
				if(currentLoading) {
					loadAfterDone = page;
					return;
				}

				$(canvases).hide();
				for(let i = 0; i < obj.previewPages; i++) {
					loadPDFPreviewPageIndex(page - obj.startOffset, i);
				}
				
			};
			var loadPDFPreviewPageIndex = function(pageNumber, index) {
				if((pageNumber + index) <= 0 || (pageNumber + index) > pdf.numPages) {
					return;
				}

				currentLoading++;
				pdf.getPage(pageNumber + index).then(function(page) {
					var pdfWidth = page.view[2];
					var pdfHeight = page.view[3];
					var windowWidth = $(window).width();
					var windowHeight = $(window).height();

					var scale = Math.min(windowHeight * 0.65 / pdfHeight, windowWidth * (0.8 / obj.previewPages) / pdfWidth, windowWidth * 0.5 / pdfWidth);
					// Wide screen pdfs need full space
					if(pdfWidth > pdfHeight) {
						if(obj.previewPages > 1) {
							dialog.removeClass('large').addClass('fullscreen');
						} else {
							dialog.addClass('large');
						}
					}
					var viewport = page.getViewport({scale: scale});

					var context = canvases[index].getContext('2d');
					canvases[index].height = viewport.height;
					canvases[index].width = viewport.width;
					$(canvases[index]).show();

					var displayPage = pageNumber;
					if(displayPage === 0) {
						displayPage = 1;
					}

					if(obj.specialPageDisplay[displayPage]) {
						displayPage = obj.specialPageDisplay[displayPage];
					} else if(obj.specialPageDisplay[displayPage - pdf.numPages - 1]) {
						displayPage = obj.specialPageDisplay[displayPage - pdf.numPages - 1];
					} else if(obj.pdfPageOffset) {
						displayPage = 'Page ' + (displayPage - obj.pdfPageOffset);
					} else {
						displayPage = 'Page ' + displayPage;
					}
					var pageTotal = pdf.numPages;
					if(obj.pdfPageTotalOffset) {
						pageTotal -= obj.pdfPageTotalOffset;
					}

					var renderContext = {
						canvasContext: context,
						viewport: viewport
					};
					page.render(renderContext).promise.then(function() {
						currentLoading--;

						if(obj.onLoadPdfPage) {
							obj.onLoadPdfPage();
						}
					});
					dialog.find('#preview-page-pages').text(' ' + displayPage + ' of ' + pageTotal + ' ');
					updatePageButtons();

					if (loadAfterDone) {
						window.setTimeout(function () {
							if(!currentLoading) {
								loadPDFPreviewPage(loadAfterDone);
								loadAfterDone = null;
							}
						}, 10);
					}

					dialog.modal('refresh');
				}, function (error) {
					console.error(error);
					$(dialog).find('.content').html('<font color="red">Error: Failed To Load</font>');
				});
			};
			var updatePageButtons = function () {
				if(pdf) {
					if(pdf.numPages > obj.previewPages) {
						if (currentPage > 1) {
							dialog.find('#preview-page-previous').removeClass('disabled');
						} else {
							dialog.find('#preview-page-previous').addClass('disabled');
						}

						if (currentPage < pdf.numPages) {
							dialog.find('#preview-page-next').removeClass('disabled');
						} else {
							dialog.find('#preview-page-next').addClass('disabled');
						}
					} else {
						$('#preview-page-pager').hide();
					}
				} else {
					dialog.find('.content .button').addClass('disabled');
				}
			};

			var url;
			if (data.url) {
				url = data.url;
			} else {
				url = 'ajax/getPdf.php?file=' + filename;
			}

			window.pdfjsLib.getDocument({url: url}).promise.then(function (mPdf) {
				$(dialog).find('.content').empty();
				pdf = mPdf;

				// Setup dialog for displaying the preview pdf
				for(let i = 0; i < obj.previewPages; i++) {
					canvases.push($('<canvas style="border:1px solid black;">').attr('id', 'preview-page-canvas' + i)[0]);
				}
				if(obj.previewPages > 1) {
					dialog.addClass('large');
				}
				for(var id in obj.renderTargets) {
					if(obj.renderTargets[id].layoutsPerPage > 1) {
						dialog.addClass('large');
					}
				}

				var pager = $('<div id="preview-page-pager" align="center">');
				$('<div id="preview-page-previous" class="ui button">Previous</div>').click(function () {
					currentPage -= obj.previewPages;
					loadPDFPreviewPage(currentPage);
				}).appendTo(pager);
				$('<span id="preview-page-pages"> Page </span>').appendTo(pager);
				$('<div id="preview-page-next" class="ui button">Next</div>').click(function () {
					currentPage += obj.previewPages;
					loadPDFPreviewPage(currentPage);
				}).appendTo(pager);

				$(dialog).find('.content').append(canvases).append(pager);

				var saveButton = $('<a class="ui icon button" style="float: right;" href="ajax/getPdf.php?file=' + filename + '" target="_blank"><i class="download icon"></i></div>');
				saveButton.click(function() {
					$.downloadButtonLastClicked = new Date().getTime();
				});
				saveButton.attr('data-tooltip', 'Download proof PDF');
				$(dialog).find('.header').append(saveButton);

				loadPDFPreviewPage(currentPage);
			}, function (error) {
				console.error(error);
				$(dialog).find('.content').html('<font color="red">Error: Failed To Load</font>');
			});
			updatePageButtons();
		},

		showImageDialog: function (data) {
			var me = this;
			this.dialog = $('<div class="ui large modal"><i class="close icon"></i><div class="header">Generating Elements</div></div>')
				.append('<div class="content" style="text-align: center;"><div class="ui active inverted dimmer"><div class="ui text loader"></div></div></div>')
				.append('<div class="actions"><div class="ui negative button">Close</div></div>')
				.modal({
					onVisible: function () {
						if (data) {
							me.openImagePreview(data);
						}
					},
					onHidden: function () {
						me.dialogClosed = true;
						$(this).remove();
						$('body').css('height', '');
					},
					closable: false
				}).modal('show');


			if (!data) {
				this.generatePDFFromPages(function (data) {
					$.ajax({
						url: 'ajax/getPdfPages.php',
						data: {
							uuid: data.id
						},
						dataType: 'json',
						type: 'POST',
						success: function (val) {
							me.openImagePreview($.extend({
								pages: val.pages
							}, data));
						},
						error: function () {
							me.handleError();
						}
					});
				});
			}
		},
		openImagePreview: function (data) {
			var dialog = this.dialog;
			var header = dialog.find('.header');
			header.text((this.production ? 'Production' : 'Preview') + ' Elements');
			if (this.showDownloadButton) {
				$('<a class="ui icon green button" style="float: right;" target="_blank"><i class="download icon"></i></a>').click(function() {
					$.downloadButtonLastClicked = new Date().getTime();
				}).attr('href', 'ajax/getPdfImage.php?id=' + data.id + '&page=*').attr('data-tooltip', 'Download ZIP renders').appendTo(header);
			}

			var content = dialog.find('.content');
			content.empty();

			if(data.created && moment().format('L') !== data.created) {
				$('<div class="ui warning message">').text('This render is from ' + data.created + ' and might be out of date').appendTo(content);
			}

			var slider = $('<div id="previewSlider" class="mightySlider mightyslider_carouselSimple_skin" style="min-height: 400px"><div id="previewSliderFrame" class="frame" style="min-height: 400px"><ul id="previewList" class="slideelement"></ul></div><div class="scrollbar"><div class="handle"><div class="mousearea"></div></div></div></div>');
			var frame = slider.find('.slideelement');

			var pagesIsArray = $.isArray(data.pages);
			var total = pagesIsArray ? data.pages.length : data.pages;
			for (let i = 0; i < total; i++) {
				var url;
				if (pagesIsArray) {
					url = data.pages[i];
				} else {
					url = 'ajax/getPdfImage.php?id=' + data.id + '&page=' + i + '&random=' + $.createRandomString(5);
				}

				frame.append('<li data-mightyslider="cover: \'' + url + '\'"></li>');
			}
			content.append(slider);

			$('#previewSliderFrame').mightySlider({
				viewport: 'fit',
				navigation: {
					navigationType: 'basic',
					keyboardNavBy: 'slides',
					slideSize: '40%'
				},
				dragging: {
					mouseDragging: 0,
					touchDragging: 0
				},
				scrollBar: {
					scrollBarSource: '#previewSlider .scrollbar'
				},
				scrolling: {
					scrollSource: '#previewSlider',
					scrollBy: 1
				}
			}, {
				coverInserted: function (event, elem) {
					$(elem).siblings('.dimmer').remove();
				},
				beforeCoverLoad: function (event, index) {
					var elem = frame.children('li').eq(index);

					elem.find('.mSLoader').remove();
					elem.append('<div class="ui active inverted dimmer"><div class="ui text loader"></div></div>');
				}
			});
			dialog.modal('refresh');
		},

		handleError: function (message) {
			if(!message) {
				message = 'Error: Failed To Load';
			} else if(message.indexOf('on page ') !== -1 && this.renderTargets.html && this.renderTargets.html.pageSet) {
				var matches = message.match(/on page \d+/g);
				if(matches.length) {
					var pageNumber = parseInt(matches[0].replace('on page ', ''));
					var matchedPage = this.renderTargets.html.pageSet.getPageByPageNumber(pageNumber);
					if(matchedPage) {
						message = message.replace(matches[0], 'on page ' + matchedPage.getPageReference());
					}
				}
			}

			if(this.dialog) {
				$(this.dialog).find('.content').html('<font color="red">' + message + '</font>');
			} else {
				$.Alert('Error', message);
			}
			if(this.onError) {
				this.onError();
			}
		},
		pageOffset: 0,
		layoutsPerPage: 1,
		ratio: $.PRODUCTION_RATIO,
		blockEmail: false,
		specialPageDisplay: {},
		displayCropMarks: false,
		forceSide: null,
		extraPostData: {},
		previewPages: 1,
		startOffset: 0,
		showBleedColor: false,
		hideBleed: false,
		checkProductSKUs: false
	}, options);

	if(renderTargets === null) {
		obj.renderTargets = null;
	} else if(renderTargets.contextAlias && renderTargets.contextAlias == 'pageSet') {
		obj.renderTargets = {
			html: {
				id: 'html',
				pageSet: renderTargets,
				layoutsPerPage: obj.layoutsPerPage
			}
		};
	} else {
		for(let i in renderTargets) {
			let renderTarget = renderTargets[i];
			if(!renderTarget.layoutsPerPage) {
				renderTarget.layoutsPerPage = obj.layoutsPerPage;
			}
			renderTarget.id = i;

			if(renderTarget.waitFor) {
				if(!$.isArray(renderTarget.waitFor)) {
					renderTarget.waitFor = [renderTarget.waitFor];
				}

				renderTarget.waitFor.forEach(function(waitForName, index) {
					renderTarget.waitFor[index] = renderTargets[waitForName];
				});
			}
		}
		obj.renderTargets = renderTargets;
	}

	return obj;
};