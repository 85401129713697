$.FlowLayoutBleedMask = function(options) {
	var div = document.createElement('div');
	div.className = 'flowLayoutBleedMask';

	$.extend(div, {
		init: function() {
			this.initHoverTooltips();
		},
		initHoverTooltips: function() {
			this.leftBleedArea = $('<div class="hoverTooltipArea verticalArea">').popup({
				content: this.bleedWarningTooltip,
				position: 'left center',
				on: 'manual',
				onShow: function(el) {
					var popup = $(this).find('.content');
					if(el.customTooltip) {
						popup.text(el.customTooltip);
					} else {
						popup.text(div.bleedWarningTooltip);
					}
				}
			});

			this.rightBleedArea = $('<div class="hoverTooltipArea verticalArea">').popup({
				content: this.bleedWarningTooltip,
				position: 'right center',
				on: 'manual',
				onShow: function(el) {
					var popup = $(this).find('.content');
					if(el.customTooltip) {
						popup.text(el.customTooltip);
					} else {
						popup.text(div.bleedWarningTooltip);
					}
				}
			});

			this.topBleedArea = $('<div class="hoverTooltipArea horizontalArea">').popup({
				content: this.bleedWarningTooltip,
				position: 'top center',
				on: 'manual',
				onShow: function(el) {
					var popup = $(this).find('.content');
					if(el.customTooltip) {
						popup.text(el.customTooltip);
					} else {
						popup.text(div.bleedWarningTooltip);
					}
				}
			});

			this.bottomBleedArea = $('<div class="hoverTooltipArea horizontalArea">').popup({
				content: this.bleedWarningTooltip,
				position: 'bottom center',
				on: 'manual',
				onShow: function(el) {
					var popup = $(this).find('.content');
					if(el.customTooltip) {
						popup.text(el.customTooltip);
					} else {
						popup.text(div.bleedWarningTooltip);
					}
				}
			});

			this.leftSafeArea = $('<div class="hoverTooltipArea verticalArea">').popup({
				content: this.safeWarningTooltip,
				position: 'left center',
				on: 'manual'
			});

			this.rightSafeArea = $('<div class="hoverTooltipArea verticalArea">').popup({
				content: this.safeWarningTooltip,
				position: 'right center',
				on: 'manual'
			});

			this.topSafeArea = $('<div class="hoverTooltipArea horizontalArea">').popup({
				content: this.safeWarningTooltip,
				position: 'top center',
				on: 'manual'
			});

			this.bottomSafeArea = $('<div class="hoverTooltipArea horizontalArea">').popup({
				content: this.safeWarningTooltip,
				position: 'bottom center',
				on: 'manual'
			});

			$(this.flowLayout).on('mousemove', this.mouseMoveHandler = function(event) {
				$(div).css('pointer-events', 'auto');
				var topElement = document.elementFromPoint(event.clientX, event.clientY);
				if($(topElement).hasClass('hoverTooltipArea')) {
					if(div.currentTooltipShown && div.currentTooltipShown !== topElement) {
						$(div.currentTooltipShown).popup('hide');
					}
					div.currentTooltipShown = topElement;
					$(topElement).popup('show');
				} else if(div.currentTooltipShown) {
					$(div.currentTooltipShown).popup('hide');
					div.currentTooltipShown = null;
				}
				$(div).css('pointer-events', '');
			}).on('mouseout', this.mouseOutHandler = function() {
				if(div.currentTooltipShown) {
					$(div.currentTooltipShown).popup('hide');
					div.currentTooltipShown = null;
				}
			});

			$(this).append(this.leftBleedArea).append(this.rightBleedArea)
				.append(this.topBleedArea).append(this.bottomBleedArea);
			$(this).append(this.leftSafeArea).append(this.rightSafeArea)
				.append(this.topSafeArea).append(this.bottomSafeArea);
		},
		setRatio: function(ratio) {
			this.ratio = ratio;
		},
		setBleed: function(bleed, margins) {
			this.setBleedForArea('Bleed', bleed, {}, 0.6, 0.4, 'bleedHighlightColor');
			this.setBleedForArea('Safe', margins, bleed, 0.4, 0.15, 'safeSpaceHighlightColor', 'bleedHighlightColor');
		},
		setBleedForArea: function(name, bleed, excludeArea, whiteTransparency, coloredTransparency, highlightColorSetting, backupHighlightColorSetting) {
			var page = this.flowLayout.getPage();
			var customBleedSettings = {};
			if(name === 'Bleed' && page && page.getCustomBleedSettings) {
				customBleedSettings = page.getCustomBleedSettings();
			}

			var css = {};
			for(var side in bleed) {
				var customBleedSetting = customBleedSettings[side] || {};
				var bleedColor = 'rgba(255, 255, 255, ' + whiteTransparency + ')';
				try {
					var studioColor = $.getStudioSetting(highlightColorSetting);
					if(!studioColor) {
						studioColor = $.getStudioSetting(backupHighlightColorSetting);
					}
					if(studioColor && studioColor !== '#ffffff') {
						let colors;
						if(studioColor.startsWith('rgb')) {
							colors = $.convertRGBToArray(studioColor);
						} else {
							colors = $.convertHexToRGB(studioColor, true);
						}
						if(!isNaN(colors[0]) && !isNaN(colors[1]) && !isNaN(colors[2])) {
							bleedColor = 'rgba(' + colors[0] + ', ' + colors[1] + ', ' + colors[2] + ', ' + coloredTransparency + ')';
						}
					}
				} catch(e) {
					console.error('Failed to parse studio color', e);
				}

				if(customBleedSetting.color) {
					bleedColor = customBleedSetting.color;
				}
				if(customBleedSetting.tooltip) {
					this[side + name + 'Area'][0].customTooltip = customBleedSetting.tooltip;
				} else {
					delete this[side + name + 'Area'][0].customTooltip;
				}

				// Ceil is to get around browser rounding sub pixel values
				var sideBleed = Math.ceil(bleed[side]);
				var sideExclude = Math.ceil(excludeArea[side] || 0);
				var sideSize = sideBleed - sideExclude;

				if(!sideSize) {
					this[side + name + 'Area'].css({
						display: 'none'
					});
					continue;
				}

				switch(side) {
					case 'left':
						this['left' + name + 'Area'].css({
							width: sideSize,
							left: sideExclude,
							height: 'calc(100% - ' + (Math.ceil(bleed.top) + Math.ceil(bleed.bottom)) + 'px)',
							top: Math.ceil(bleed.top),
							background: bleedColor,
							display: ''
						});
						break;
					case 'right':
						this['right' + name + 'Area'].css({
							width: sideSize,
							right: sideExclude,
							height: 'calc(100% - ' + (Math.ceil(bleed.top) + Math.ceil(bleed.bottom)) + 'px)',
							top: Math.ceil(bleed.top),
							background: bleedColor,
							display: ''
						});
						break;
					case 'top':
						this['top' + name + 'Area'].css({
							height: sideSize,
							top: sideExclude,
							width: 'calc(100% - ' + (Math.ceil(excludeArea.left || 0) + Math.ceil(excludeArea.right || 0)) + 'px)',
							left: Math.ceil(excludeArea.left || 0),
							background: bleedColor,
							display: ''
						});
						break;
					case 'bottom':
						this['bottom' + name + 'Area'].css({
							height: sideSize,
							bottom: sideExclude,
							width: 'calc(100% - ' + (Math.ceil(excludeArea.left || 0) + Math.ceil(excludeArea.right || 0)) + 'px)',
							left: Math.ceil(excludeArea.left || 0),
							background: bleedColor,
							display: ''
						});
						break;
				}
			}

			$(this).css(css);
		},
		destroy: function() {
			$(this).find('.hoverTooltipArea').popup('destroy');
			$(this).remove();

			$(this.flowLayout).off('mousemove', this.mouseMoveHandler);
			$(this.flowLayout).off('mouseout', this.mouseOutHandler);
		},

		bleedWarningTooltip: 'Content in this area IS going to be cut out of the Book',
		safeWarningTooltip: 'Content in this area MIGHT get cut out of the book if it gets too close to the edge'
	}, options);
	div.init();

	return div;
};