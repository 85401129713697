$.Proof = function(settings) {
	var obj = new Object;

	$.extend(obj, {
		insertRender: function(render) {
			var renderPage = this.db.createRenderFromData(render);
			renderPage.db = this.db;
			renderPage.visible = true;
			this.renders.splice(render.pageNumber - 1, 0, renderPage);

			var renderQueueValue = $.extend({
				compositeProofRenderId: renderPage.id,
				insert: true
			}, render);
			delete renderQueueValue.imageUrl;

			this.db.queueChange({
				scope: 'renders',
				name: renderPage.id,
				value: renderQueueValue
			});

			for(var i = render.pageNumber; i < this.renders.length; i++) {
				var incrementRender = this.renders[i];
				incrementRender.setProperty('pageNumber', incrementRender.pageNumber + 1);
			}

			return renderPage;
		},
		deleteRender: function(render) {
			let index = this.renders.indexOf(render);
			this.renders.removeItem(render);

			this.db.queueChange({
				scope: 'renders',
				name: render.id,
				value: {
					delete: true
				}
			});

			if(index !== -1) {
				for(var i = index; i < this.renders.length; i++) {
					var decrementRender = this.renders[i];
					decrementRender.setProperty('pageNumber', decrementRender.pageNumber - 1);
				}
			}
		},
		addRender: function(render) {
			render.db = this.db;
			this.renders.push(render);
		},
		getRenders: function() {
			return this.renders;
		},

		submit: function() {
			this.approvedAt = moment().toISOString();

			this.db.queueChange({
				scope: 'proof',
				name: 'approvedAt',
				value: this.approvedAt
			});
		},
		unsubmit: function() {
			this.approvedAt = null;

			this.db.queueChange({
				scope: 'proof',
				name: 'approvedAt',
				value: this.approvedAt
			});
		},
		setCustomerDownload: function(value) {
			this.customerDownload = value;

			this.db.queueChange({
				scope: 'proof',
				name: 'customerDownload',
				value: this.customerDownload
			});
		},
		setEnabled: function(value) {
			this.enabled = value;

			this.db.queueChange({
				scope: 'proof',
				name: 'enabled',
				value: this.enabled
			});
		},
		setCanEditRowNames: function(value) {
			this.canEditRowNames = value;

			this.db.queueChange({
				scope: 'proof',
				name: 'canEditRowNames',
				value: this.canEditRowNames
			});
		},
		setExtraDataToCollect: function(value) {
			this.extraDataToCollect = value;

			this.db.queueChange({
				scope: 'proof',
				name: 'extraDataToCollect',
				value: this.extraDataToCollect
			});
		},
		setDeadline: function(value) {
			this.deadline = value;

			this.db.queueChange({
				scope: 'proof',
				name: 'deadline',
				value: this.deadline
			});
		},
		getDeadlineDays: function() {
			if(this.deadline) {
				let now = new Date();
				let deadline = Date.parse(this.deadline);
				let timeDiff = deadline - now.getTime();
				return Math.ceil(timeDiff / (1000 * 3600 * 24));
			} else {
				return 1;
			}
		},

		renders: []
	}, settings);

	return obj;
};